<script setup lang="ts">
defineProps<{ expanded: boolean }>()
const client = useClient()
const supabase = useSupabaseClient()
const auth = useAuth()

const isLoggingOut = ref(false)

const links = computed(() => {
   return [
    [
      {
        icon: resolveComponent('IconForgd'),
        to: '/',
      },
    ],
    [
      {
        icon: 'i-heroicons-home',
        to: '/dashboard/',
        label: 'Home',
      },
      {
        icon: 'i-heroicons-squares-plus',
        to: '/token-designer/',
        label: 'Token Designer',
      },
      {
        icon: resolveComponent('IconToolsAndServices'),
        to: '/tools-and-services/',
        label: 'Tools & Services',
        children: [
          { label: 'Tokenomics', to: '/tokenomics/' },
          { label: 'Growth Capital', to: '/growth-capital/' },
          { label: 'AutoDistribution', to: '/auto-distribution/' },
          { label: 'Listing & Liquidity', to: '/listing-and-liquidity/' },
          { label: 'Business Intelligence', to: '/business-intelligence/' },
          { label: 'AMM²', to: '/automated-market-making/' },
          {
            label: 'Engage a Market Maker',
            to: '/engage-a-market-maker/',
          },
          {
            label: 'Market Maker Monitoring',
            to: '/market-maker-monitoring/start',
          },
        ],
      },
      {
        icon: resolveComponent('IconPathFinder'),
        to: '/pathfinder/',
        label: 'Pathfinder',
      },
      {
        icon: 'i-heroicons-document-text',
        to: '/about/',
        label: 'About',
        children: [
          { name: 'What is Forgd?', to: 'https://forgd.com/about', external: true },
          { name: 'How to Work with Us', to: 'https://forgd.com/how-to-work-with-us', external: true },
          { name: 'Coming Soon Tools', to: 'https://forgd.com/tools#coming-soon', external: true },
        ],
      },
    ],
    [
      {
        icon: 'i-heroicons-question-mark-circle',
        // TODO fix link
        to: '/support/',
        label: 'FAQs',
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        to: '/support/',
        label: 'Support',
      },
      {
        icon: 'i-heroicons-cog-8-tooth',
        to: '/settings/',
        label: 'Settings',
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        on: {
          click: logout,
        },
      },
    ],
  ]
})

async function logout() {
  isLoggingOut.value = true
  await supabase.auth.signOut()
    .catch(() => {
      isLoggingOut.value = false
    })
  auth.logout()
}
</script>

<template>
  <div
    class="px-2 flex-shrink-0 border-r bg-forgd-primary-900 font-display max-h-screen z-10 pb-2 sticky top-0"
  >
    <nav
      class="flex flex-col h-full gap-8 pt-8 pb-2"
      :class="expanded ? 'w-[260px]' : ''"
      aria-label="Site Navigation"
    >
      <NuxtLink to="/">
      <IconForgd :expanded="expanded" />
      </NuxtLink>
      <div class="h-full flex gap-8 flex-col justify-between overflow-y-auto no-scrollbar">
        <AppMenuExpandableNavigationList
          v-for="(link, key) in links.slice(1)"
          :key="key"
          :items="link"
          :expanded="expanded"
        />
      </div>
    </nav>
  </div>
</template>
